<!-- System: STA
    Purpose: This compoment will display
            a header showing date, time worked and play video button
-->
<template>
  <div class="col-12 px-0">
    <div v-if="selectedCompanyUserStatus !== 'employee' && (mouseClicks > 0 || keyStrokes > 0)">
      <div
        :class="{
          'playButon-margin-top':
            this.$parent.$parent.selectedCompanyUserStatus === 'employee',
        }"
        class="col-12 px-0 py-0 text-left"
      >
        <v-tooltip v-if="video && video !== 'none'" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2758f6"
              v-bind="attrs"
              v-on="on"
              @click="playVideo"
              class="playButon hidebtn white--text ml-3"
            >
              &nbsp;Play Video
            </v-btn>
          </template>
          <span>Play Video</span>
        </v-tooltip>
        <v-tooltip v-else left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2758f6"
              v-bind="attrs"
              v-on="on"
              @click="createVideo"
              class="playButon hidebtn white--text ml-3"
            >
              &nbsp;Create Video
            </v-btn>
          </template>
          <span>Play Video</span>
        </v-tooltip>
      </div>
      <v-card
        elevation="5"
        class="d-md-flex d-grid col-12 progress-div mt-3 pagination-row-w-d my-row-setting progress-bar-times text-left"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <h5
              class="col-md-4 col-12 d-grid pl-2 my-auto"
              v-bind="attrs"
              v-on="on"
            >
              <p class="time-span mb-2">{{ time }}</p>
              <p class="Worked" title="Hours:Minutes">
                Total Time Worked {{ min_to_hour(totalWorkedTime) }}
              </p>
            </h5>
          </template>
          <span>Hours:Minutes</span>
        </v-tooltip>
        <div>
          <v-tooltip v-if="video && video !== 'none'" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#2758f6"
                @click="playVideo"
                v-bind="attrs"
                v-on="on"
                class="hand fa fa-play btn btn-theme showThis white--text"
              >
                &nbsp;Play Video
              </v-btn>
            </template>
            <span>Play Video</span>
          </v-tooltip>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#2758f6"
                @click="createVideo"
                v-bind="attrs"
                v-on="on"
                class="hand fa fa-play btn btn-theme showThis white--text"
              >
                &nbsp;Create Video
              </v-btn>
            </template>
            <span>Create Video</span>
          </v-tooltip>
        </div>
        <div
          class="d-md-grid d-flex col-md-8 col-12 icon-main-div progress-mouse-click pa-0"
        >
          <div
            class="col-3 d-sm-flex d-grid"
          >
            <img class="clock-icon" src="../../assets/images/progress.svg" />
            <span class="progress-align">
              <span class="detail">{{ progress }}%</span>
              <span class="heading">Progress</span>
            </span>
          </div>
          <div
            class="col-3 d-sm-flex d-grid"
            v-if="
              selectedCompanyUserStatus !== 'employee'
            "
          >
            <img class="clock-icon" src="../../assets/images/mouse .svg" />
            <span class="progress-align">
              <span class="detail">{{ mouseClicks }}</span>
              <small class="heading">Mouse Clicks</small>
            </span>
          </div>

          <div
            class="col-3 d-sm-flex d-grid"

          >
            <img class="clock-icon" src="../../assets/images/keyboard.svg" />
            <span class="progress-align">
              <span class="detail">{{ keyStrokes }}</span>
              <small class="heading">Key Strokes</small>
            </span>
          </div>

          <div
            class="col-3 d-sm-flex d-grid"

          >
            <img class="clock-icon" src="../../assets/images/idle.svg" />
            <span class="progress-align">
              <span class="detail">{{ idle }}%</span>
              <small class="heading">Idle</small>
            </span>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else>
      <v-card
        elevation="5"
        class="d-flex flex-wrap justify-space-around align-items-center mt-3 mb-3 pagination-row-w-d"
      >
        <div class="my-auto">
          <span class="p-2 time-span time-workdiary custom-time-style"
            ><v-icon>mdi-calendar-today</v-icon> {{ time }}</span
          >
        </div>
        <div class="my-auto mx-16">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="time-span custom-time-style"
                v-bind="attrs"
                v-on="on"
              >
                <img src="@/assets/images/schedule icon.svg" width="20px" />
                <span class="total-time-custom">
                  Total Time Worked {{ min_to_hour(totalWorkedTime) }}</span
                >
              </span>
            </template>
            <span>Hours:Minutes</span>
          </v-tooltip>
        </div>
        <div class="my-3">
          <v-tooltip v-if="video && video !== 'none'" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#2758f6"
                @click="playVideo"
                v-bind="attrs"
                v-on="on"
              >
                &nbsp;Play Video
              </v-btn>
            </template>
            <span>Play Video</span>
          </v-tooltip>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#2758f6"
                class="white--text"
                @click="createVideo"
                v-bind="attrs"
                v-on="on"
              >
                &nbsp;Play Video
              </v-btn>
            </template>
            <span>Play Video</span>
          </v-tooltip>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { min_to_hour } from "@/assets/js/utils";
import moment from "moment";
import _ from "lodash";
import { mapState } from "vuex";

export default {
  name: "pagination-bar",
  components: {
    moment,
  },
  props: [
    "index",
    "time",
    "totalSize",
    "workedTime",
    "video",
    "keyStrokes",
    "mouseClicks",
    "progress",
    "screenStats",
    "totalWorkedTime",
  ],
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    prev() {
      return this.index - 1;
    },
    next() {
      return this.index + 1;
    },
    idle() {
      return 100 - this.progress;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        setTimeout(() => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Select company first",
            snackbarColor: "red",
          });
        }, 3000);
        return this.$router.push({ name: "CompaniesListing" });
      }
    },
  },
  mounted() {
    this.nextDay();
    this.previousDay();
  },
  watch: {
    next: function () {
      this.nextDay();
    },
    prev: function () {
      this.previousDay();
    },
  },
  methods: {
    nextDay: _.debounce(
      function () {
        this.startLoader();
        return this.next;
      },
      1000,
      { leading: true, trailing: false }
    ),

    previousDay: _.debounce(
      function () {
        this.startLoader();
        return this.prev;
      },
      1000,
      { leading: true, trailing: false }
    ),

    startLoader() {
      this.$store.commit("custom/toggle_progress_loader", true);
      setTimeout(() => {
        this.$store.commit("custom/toggle_progress_loader", false);
      }, 1200);
    },

    min_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hrs = time.hrs;
      let mins = time.mins;
      if (hrs <= 9) {
        hrs = "0" + time.hrs;
      }
      if (mins <= 9) {
        mins = "0" + time.mins;
      }
      if (hrs == "00") {
        return hrs + ":" + mins + " MINUTES";
      } else {
        return hrs + ":" + mins + " HRS";
      }
    },
    playVideo() {
      this.$root.$emit("bv::show::modal", "video_modal");
    },
    createVideo() {
      this.$root.$emit("createVideoEmit");
    },
  },
};
</script>

<style scoped>
.pagination-row-w-d {
  /*border: 1px solid #2758f6;*/
  /*border-radius: 3px;*/
  /*background-color: #f1f6ff;*/
  width: 99%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 25px 35px -20px rgba(172, 184, 207, 0.34);
    margin-left: 11px;
}
.custom-time-style {
  color: #2c2c2c;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
}

.showThis {
  display: none;
}

.progress-div {
  justify-content: space-between;
}

.progress-align {
  display: grid;
  padding-left: 10px;
}
</style>
